@import "./../../../resources/scss/variables";

.wrapper-navigation {
    height: 100vh;
    position: relative;
    min-width: 76px;
}

.navigation-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #000;
    opacity: 0.3;
    display: none;
    z-index: 9;
}

.navigation-overlay.opened {
    display: block;
}

.navigation  {
    position: absolute;
    border-right: 1px solid $low-color-dark;
    transition: width 300ms;
    background-color: #fff;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
}

.navigation .menu-toggle {
    position: absolute;
    right: -12px;
    top: 28px;
    background-color: $brand-primary-default;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
}

.navigation .logo {
    padding: 24px 16px;
    background-color: #f3a32f;
}

.navigation .logo a {
    display: flex;
    align-items: center;
    font-size: $font-size-18;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-bold;
    line-height: 21.6px;
}
.navigation .logo a:hover {
    text-decoration: none;
}
.navigation .logo a .name {
    margin-left: 16px;
}
.navigation.closed .logo a {
    justify-content: center;
}
.navigation.closed .logo a .name {
    display: none;
}

.navigation .menu {
    margin-top: 6px;
    list-style-type: none;
    padding: 0;
    display: block;
    padding: 0 10px;
}

.navigation .menu li {
    display: block;
}

.navigation .menu li a.link {
    display: flex;
    align-items: center;
    font-family: $font-family-sans-serif;
    font-size: $font-size-14;
    line-height: $line-height-18;
    font-weight: $font-weight-medium;
    height: 44px;
    position: relative;
    cursor: pointer;
}

.navigation.closed .menu li a.link {
    justify-content: center;
}

.navigation.closed .menu li a.link span.icon {
    padding: 0;
}

.navigation .menu li a.link span.icon {
    position: relative;
    margin-top: -5px;
    text-align: center;
    display: flex;
    width: 40px;
    align-items: center;
    justify-content: center;
}
.navigation .menu li a.link span.icon i {
    font-size: 19px;
}
.navigation .menu li a.link span.label {
    display: block;
    flex: 1;
}
.navigation.closed .menu li a.link span.label {
    display: none;
}

.navigation .menu li a.link span.new {
    width: 39px;
    height: 16px;
    background-color: $brand-secondary-default;
    border-radius: $border-radius-4;
    font-family: $font-family-sans-serif;
    font-size: $font-size-12;
    padding: 0 4px;
    line-height: $line-height-14;
    font-weight: $font-weight-bold;
    color: $low-color-light;
}
.navigation.closed .menu li a.link span.new {
    display: none;
}

.navigation.opened .menu li a.link span.new-closed {
    display: none;
}

.navigation .menu li a.link span.new-closed {
    font-family: $font-family-sans-serif;
    font-size: $font-size-12;
    font-weight: $font-weight-bold;
    color: $brand-secondary-default;
    line-height: $line-height-14;
    position: absolute;
    right: 3px;
    bottom: 1px;
}

.navigation .menu li a.link:hover,
.navigation .menu li a.link span.icon,
.navigation .menu li a.link span.label,
.navigation .menu li a.link span.new {
    text-decoration: none;
}