/**
* Author: Viníccius Guedes
* Description: Arquivo somente para armazenamento de variaveis reutilizaveis
* Year: 2024
*/

// Prefixo [Design System Z360 => dsz]
$prefix_class: ".dsz";
$prefix_id: "#dsz";

// Colors primary
$brand-primary-default: #3B45F2;
$brand-primary-dark: #0D1874;
$brand-primary-medium: #4F83FB;
$brand-primary-light: #CADAFF;

$brand-secondary-default: #8CD92A;
$brand-secondary-dark: #3C7A2C;
$brand-secondary-medium: #D3FF9A;
$brand-secondary-light: #EEFFD8;

// Colors Alert
$alert-color-default: #E81E42;
$alert-color-dark: #B4052F;
$alert-color-medium: #FF7495;
$alert-color-light: #FFC7D8;

$success-color-default: #0AED9B;
$success-color-dark: #0AB16B;
$success-color-medium: #6CF4C3;
$success-color-light: #C1FAE6;

// Neutral colors

// Exchange with low
$high-color-default: #111119;
$high-color-dark: #505255;
$high-color-medium: #88898C;
$high-color-light: #B8B9BE;

// Exchange with high
$low-color-default: #FFFFFF;
$low-color-dark: #CFD0DA;
$low-color-medium: #E8E8EE;
$low-color-light: #F5F5FA;

// Typography
$font-family-sans-serif: 'Figtree';
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

// Font Size
$font-size-64: 64px;
$font-size-48: 48px;
$font-size-40: 40px;
$font-size-32: 32px;
$font-size-24: 24px;
$font-size-28: 28px;
$font-size-20: 20px;
$font-size-18: 18px;
$font-size-16: 16px;
$font-size-14: 14px;
$font-size-12: 12px;
$font-size-inherit: inherit;

// Line Height
$line-height-14:14px;
$line-height-16:16px;
$line-height-18:18px;
$line-height-20:20px;
$line-height-22:22px;
$line-height-24:24px;
$line-height-28:28px;
$line-height-30:30px;
$line-height-32:32px;
$line-height-34:34px;
$line-height-38:38px;
$line-height-40:40px;
$line-height-48:48px;
$line-height-normal:normal;
$line-height-inherit:inherit;

// Spacing
$spacing-4: 4px;
$spacing-8: 8px;
$spacing-16: 16px;
$spacing-24: 24px;
$spacing-32: 32px;
$spacing-40: 40px;
$spacing-48: 48px;
$spacing-56: 56px;
$spacing-64: 64px;
$spacing-80: 80px;
$spacing-120: 120px;
$spacing-160: 160px;
$spacing-200: 200px;

// Border
$border-width-none: 0px;
$border-width-1: 1px;
$border-width-2: 2px;
$border-width-4: 4x;
$border-width-6: 6px;
$border-width-8: 8px;
$border-width-12: 12px;

// Border Radius
$border-radius-none: 0px;
$border-radius-4: 4px;
$border-radius-8: 8px;
$border-radius-12: 12px;
$border-radius-16: 16px;
$border-radius-24: 24px;
$border-radius-pill: 500px;
$border-radius-circular: 100%;

// Grid
$grid-container-mobile: 360px;
$grid-container-web-small: 1024px;
$grid-container-web-medium: 1364px;
$grid-container-web-large: 1844px;

// Width
$w-10vw: 10vw;
$w-25vw: 25vw;
$w-50vw: 50vw;
$w-75vw: 75vw;
$w-100vw: 100vw;

// Height
$h-10vh: 10vh;
$h-25vh: 25vh;
$h-50vh: 50vh;
$h-75vh: 75vh;
$h-100vh: 100vh;

:export {
    brandPrimaryDefault: $brand-primary-default;
    brandPrimaryDark: $brand-primary-dark;
    brandPrimaryMedium: $brand-primary-medium;
    brandPrimaryLight: $brand-primary-light;

    brandSecondaryDefault: $brand-secondary-default;
    brandSecondaryDark: $brand-secondary-dark;
    brandSecondaryMedium: $brand-secondary-medium;
    brandSecondaryLight: $brand-secondary-light;

    alertColorDefault: $alert-color-default;
    alertColorDark: $alert-color-dark;
    alertColorMedium: $alert-color-medium;
    alertColorLight: $alert-color-light;

    successColorDefault: $success-color-default;
    successColorDark: $success-color-dark;
    successColorMedium: $success-color-medium;
    successColorLight: $success-color-light;

    highColorDefault: $high-color-default;
    highColorDark: $high-color-dark;
    highColorMedium: $high-color-medium;
    highColorLight: $high-color-light;

    lowColorDefault: $low-color-default;
    lowColorDark: $low-color-dark;
    lowColorMedium: $low-color-medium;
    lowColorLight: $low-color-light;
}